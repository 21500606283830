import { useI18n } from "vue-i18n"
import setting from "@/config/setting"
import { renderUnit } from "@/utils/common";

export default () => {
    const $t = useI18n().t;

    const columns = {
        "default_col": [{
            title: $t("user_order.fields.po_number"),
            key: "po_number",
            dataIndex: "po_number",
            width: 200,
            sorter: true,
            slots: {
                customRender: "po_number"
            }
        },
        {
            title: $t("share.ship_method"),
            key: "ship_method_name",
            dataIndex: "ship_method_name",
            width: 180,
            sorter: true,
            ellipsis: true,
            slots: {
                customRender: "ship_method_name"
            }
        },
        {
            title: $t("user_order.fields.ship_to_address_name"),
            key: "name",
            dataIndex: "name",
            sorter: true,
            width: 150
        },
        {
            title: $t("user_order.fields.package_count"),
            key: "package_count",
            dataIndex: "package_count",
            width: 100,
            sorter: true
        },
        {
            title: $t("user_order.fields.weight"),
            key: "total_weight",
            dataIndex: "total_weight",
            width: 100,
            sorter: true,
            customRender: ({
                record
            }) => {
                return `${record.total_weight} ${renderUnit(
                    record,
                    "weight_unit"
                )}`;
            }
        },
        {
            title: $t("user_order.fields.master_tracking_number"),
            key: "master_tracking_number",
            dataIndex: "master_tracking_number",
            sorter: true,
            width: 250,
            slots: {
                customRender: "tracking_number"
            }
        },
        {
            title: $t("user_order.fields.reference"),
            key: "reference2",
            dataIndex: "reference2",
            slots: {
                customRender: "reference"
            }
        },
        {
            title: $t("user_order.fields.charge"),
            key: "charge",
            dataIndex: "charge",
            width: 100,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) =>
                `${record.charge} ${record.currency}`
        },
        {
            title: $t("share.status"),
            key: "order_status",
            dataIndex: "order_status",
            width: 120,
            sorter: true,
            align: "center",
            slots: {
                customRender: "status"
            }
        },
        {
            title: $t("user_order.fields.created"),
            key: "created",
            dataIndex: "created",
            width: 150,
            sorter: true,
            align: "center",
            slots: {
                customRender: "time_tem"
            }
        },
        {
            title: $t("share.operation"),
            key: "action",
            width: 120,
            align: "center",
            fixed: "right",
            slots: {
                customRender: "action"
            }
        }
        ],
        'fhjy.lbl.morelink56.com': [
            {
                title: $t("user_order.fields.reference"),
                key: "reference2",
                dataIndex: "reference2",
                sorter: true,
                width: 150,
                slots: {
                    customRender: "reference"
                }
            },
            {
                title: $t("share.ship_method"),
                key: "ship_method_name",
                dataIndex: "ship_method_name",
                width: 180,
                sorter: true,
                ellipsis: true,
                slots: {
                    customRender: "ship_method_name"
                }
            },
            {
                title: $t("user_order.fields.ship_to_address_name"),
                key: "name",
                dataIndex: "name",
                sorter: true,
                width: 150
            },
            {
                title: $t("user_order.fields.weight"),
                key: "total_weight",
                dataIndex: "total_weight",
                width: 120,
                sorter: true,
                customRender: ({
                    record
                }) => {
                    return `${record.total_weight} ${renderUnit(
                        record,
                        "weight_unit"
                    )} (${record.package_count})`;
                }
            },
            {
                title: $t("user_order.fields.master_tracking_number"),
                key: "master_tracking_number",
                dataIndex: "master_tracking_number",
                sorter: true,
                slots: {
                    customRender: "tracking_number"
                }
            },
            {
                title: $t("user_order.fields.charge"),
                key: "charge",
                dataIndex: "charge",
                width: 100,
                sorter: true,
                ellipsis: true,
                customRender: ({
                    record
                }) =>
                    `${record.charge} ${record.currency}`
            },
            {
                title: $t("share.status"),
                key: "order_status",
                dataIndex: "order_status",
                width: 120,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "status"
                }
            },
            {
                title: $t("user_order.fields.created"),
                key: "created",
                dataIndex: "created",
                width: 150,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "time_tem"
                }
            },
            {
                title: $t("share.operation"),
                key: "action",
                width: 120,
                align: "center",
                fixed: "right",
                slots: {
                    customRender: "action"
                }
            }
        ],
        'first-star.lbl.morelink56.com': [
            {
                title: $t("user_order.fields.reference"),
                key: "reference2",
                dataIndex: "reference2",
                sorter: true,
                width: 150,
                slots: {
                    customRender: "reference"
                }
            },
            {
                title: $t("share.ship_method"),
                key: "ship_method_name",
                dataIndex: "ship_method_name",
                width: 180,
                sorter: true,
                ellipsis: true,
                slots: {
                    customRender: "ship_method_name"
                }
            },
            {
                title: $t("user_order.fields.ship_to_address_name"),
                key: "name",
                dataIndex: "name",
                sorter: true,
                width: 150
            },
            {
                title: $t("user_order.fields.weight"),
                key: "total_weight",
                dataIndex: "total_weight",
                width: 120,
                sorter: true,
                customRender: ({
                    record
                }) => {
                    return `${record.total_weight} ${renderUnit(
                        record,
                        "weight_unit"
                    )} (${record.package_count})`;
                }
            },
            {
                title: $t("user_order.fields.master_tracking_number"),
                key: "master_tracking_number",
                dataIndex: "master_tracking_number",
                sorter: true,
                slots: {
                    customRender: "tracking_number"
                }
            },
            {
                title: $t("user_order.fields.charge"),
                key: "charge",
                dataIndex: "charge",
                width: 100,
                sorter: true,
                ellipsis: true,
                customRender: ({
                    record
                }) =>
                    `${record.charge} ${record.currency}`
            },
            {
                title: $t("share.status"),
                key: "order_status",
                dataIndex: "order_status",
                width: 120,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "status"
                }
            },
            {
                title: $t("user_order.fields.created"),
                key: "created",
                dataIndex: "created",
                width: 150,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "time_tem"
                }
            },
            {
                title: $t("share.operation"),
                key: "action",
                width: 120,
                align: "center",
                fixed: "right",
                slots: {
                    customRender: "action"
                }
            }
        ],
        'hygj.lbl.morelink56.com': [
                {
                    title: $t("user_order.fields.reference"),
                    key: "reference2",
                    dataIndex: "reference2",
                    sorter: true,
                    width: 150,
                    slots: {
                        customRender: "reference"
                    }
                },
                {
                    title: $t("share.ship_method"),
                    key: "ship_method_name",
                    dataIndex: "ship_method_name",
                    width: 180,
                    sorter: true,
                    ellipsis: true,
                    slots: {
                        customRender: "ship_method_name"
                    }
                },
                {
                    title: $t("user_order.fields.ship_to_address_name"),
                    key: "name",
                    dataIndex: "name",
                    sorter: true,
                    width: 150
                },
                {
                    title: $t("user_order.fields.weight"),
                    key: "total_weight",
                    dataIndex: "total_weight",
                    width: 120,
                    sorter: true,
                    customRender: ({
                        record
                    }) => {
                        return `${record.total_weight} ${renderUnit(
                            record,
                            "weight_unit"
                        )} (${record.package_count})`;
                    }
                },
                {
                    title: $t("user_order.fields.master_tracking_number"),
                    key: "master_tracking_number",
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    slots: {
                        customRender: "tracking_number"
                    }
                },
                {
                    title: $t("user_order.fields.charge"),
                    key: "charge",
                    dataIndex: "charge",
                    width: 100,
                    sorter: true,
                    ellipsis: true,
                    customRender: ({
                        record
                    }) =>
                        `${record.charge} ${record.currency}`
                },
                {
                    title: $t("share.status"),
                    key: "order_status",
                    dataIndex: "order_status",
                    width: 120,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: $t("user_order.fields.created"),
                    key: "created",
                    dataIndex: "created",
                    width: 150,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "time_tem"
                    }
                },
                {
                    title: $t("share.operation"),
                    key: "action",
                    width: 120,
                    align: "center",
                    fixed: "right",
                    slots: {
                        customRender: "action"
                    }
                }
            ],
            'hyfba.com.cn': [
                {
                    title: $t("user_order.fields.reference"),
                    key: "reference2",
                    dataIndex: "reference2",
                    sorter: true,
                    width: 150,
                    slots: {
                        customRender: "reference"
                    }
                },
                {
                    title: $t("share.ship_method"),
                    key: "ship_method_name",
                    dataIndex: "ship_method_name",
                    width: 180,
                    sorter: true,
                    ellipsis: true,
                    slots: {
                        customRender: "ship_method_name"
                    }
                },
                {
                    title: $t("user_order.fields.ship_to_address_name"),
                    key: "name",
                    dataIndex: "name",
                    sorter: true,
                    width: 150
                },
                {
                    title: $t("user_order.fields.weight"),
                    key: "total_weight",
                    dataIndex: "total_weight",
                    width: 120,
                    sorter: true,
                    customRender: ({
                        record
                    }) => {
                        return `${record.total_weight} ${renderUnit(
                            record,
                            "weight_unit"
                        )} (${record.package_count})`;
                    }
                },
                {
                    title: $t("user_order.fields.master_tracking_number"),
                    key: "master_tracking_number",
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    slots: {
                        customRender: "tracking_number"
                    }
                },
                {
                    title: $t("user_order.fields.charge"),
                    key: "charge",
                    dataIndex: "charge",
                    width: 100,
                    sorter: true,
                    ellipsis: true,
                    customRender: ({
                        record
                    }) =>
                        `${record.charge} ${record.currency}`
                },
                {
                    title: $t("share.status"),
                    key: "order_status",
                    dataIndex: "order_status",
                    width: 120,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: $t("user_order.fields.created"),
                    key: "created",
                    dataIndex: "created",
                    width: 150,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "time_tem"
                    }
                },
                {
                    title: $t("share.operation"),
                    key: "action",
                    width: 120,
                    align: "center",
                    fixed: "right",
                    slots: {
                        customRender: "action"
                    }
                }
            ],
            'www.hyfba.com.cn': [
                {
                    title: $t("user_order.fields.reference"),
                    key: "reference2",
                    dataIndex: "reference2",
                    sorter: true,
                    width: 150,
                    slots: {
                        customRender: "reference"
                    }
                },
                {
                    title: $t("share.ship_method"),
                    key: "ship_method_name",
                    dataIndex: "ship_method_name",
                    width: 180,
                    sorter: true,
                    ellipsis: true,
                    slots: {
                        customRender: "ship_method_name"
                    }
                },
                {
                    title: $t("user_order.fields.ship_to_address_name"),
                    key: "name",
                    dataIndex: "name",
                    sorter: true,
                    width: 150
                },
                {
                    title: $t("user_order.fields.weight"),
                    key: "total_weight",
                    dataIndex: "total_weight",
                    width: 120,
                    sorter: true,
                    customRender: ({
                        record
                    }) => {
                        return `${record.total_weight} ${renderUnit(
                            record,
                            "weight_unit"
                        )} (${record.package_count})`;
                    }
                },
                {
                    title: $t("user_order.fields.master_tracking_number"),
                    key: "master_tracking_number",
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    slots: {
                        customRender: "tracking_number"
                    }
                },
                {
                    title: $t("user_order.fields.charge"),
                    key: "charge",
                    dataIndex: "charge",
                    width: 100,
                    sorter: true,
                    ellipsis: true,
                    customRender: ({
                        record
                    }) =>
                        `${record.charge} ${record.currency}`
                },
                {
                    title: $t("share.status"),
                    key: "order_status",
                    dataIndex: "order_status",
                    width: 120,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: $t("user_order.fields.created"),
                    key: "created",
                    dataIndex: "created",
                    width: 150,
                    sorter: true,
                    align: "center",
                    slots: {
                        customRender: "time_tem"
                    }
                },
                {
                    title: $t("share.operation"),
                    key: "action",
                    width: 120,
                    align: "center",
                    fixed: "right",
                    slots: {
                        customRender: "action"
                    }
                }
            ]
        
    }

    return {
        columns: columns[setting.env] || columns['default_col']
    }
}